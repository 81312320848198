<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
// import Swal from "sweetalert2";

export default {
    page: {
        title: "Detail Pra Asesmen",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Detail Pra Asesmen",
            items: [
                {
                    text: "Validasi MUK",
                    href: "/validasi-muk",
                },
                {
                    text: "Detail Pra Asesmen",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,

            // variabel referensi
            optionsMenuParent: [],

            //modal
            showModalSimpan: false,

            meta_dokumen: [
                {
                    'nama_dokumen': 'FR.VA Memberikan Kontribusi Dalam Validasi Asesmen',
                    'link': 'dokumen/files/FR VA MKVA pra asesmen'
                },
                {
                    'nama_dokumen': 'FR.IA.11 Ceklis Meninjau Instrumen Asesmen',
                    'link': 'dokumen/files/FR IA 11 MENINJAU MUK'
                },
                {
                    'nama_dokumen': 'Ceklis Konsultasi Pra Asesmen',
                    'link': 'dokumen/files/CEKLIS KONSULTASI PRA ASESMEN'
                },
                {
                    'nama_dokumen': 'Ceklis Verifikasi TUK',
                    'link': ''
                },
                {
                    'nama_dokumen': 'FR.APL-01 Permohonan Sertifikasi Kompetensi + Bukti Persyaratan Dasar',
                    'link': 'dokumen/files/FR APL 01'
                },
                {
                    'nama_dokumen': 'FR.APL.02 Asesmen Mandiri + Portofolio Peserta',
                    'link': 'dokumen/files/FR APL 02 fix'
                },
                {
                    'nama_dokumen': 'FR.MAPA.01 Merencanakan Aktivitas dan Proses Asesmen',
                    'link': 'dokumen/files/FR MAPA 01 fix'
                },
                {
                    'nama_dokumen': 'FR.MAPA.02 Peta Instrumen Asesmen Hasil Pendekatan Asesmen dan Perencanaan Kerja',
                    'link': 'dokumen/files/FR MAPA 02'
                },
                {
                    'nama_dokumen': 'SKEMA Sertifikasi + Standar Kompetensi Kerja',
                    'link': ''
                },
            ],
            link_backend: process.env.VUE_APP_BACKEND_URL_VERSION
        };
    },
    mounted() {
    },
    methods: {
        addDokumen() {
            this.meta_dokumen.push({
                nama_dokumen: "",
            });
        },
        removeDokumen(index) {
            this.meta_dokumen.splice(index, 1);
        },
        modalSuratTugasPenyusunan() {
            let self = this;
            self.showModalSuratTugas = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalSuratTugas = true;
            });
        },
        getDataTable(
            url = process.env.VUE_APP_BACKEND_URL_VERSION + "dokumen/files"
        ) {
            let self = this;

            var config = {
                method: "get",
                url: url,
                params: self.tableData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token
                },
            };

            axios(config)
                .then((response) => {
                    console.log(response);
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        showSuratTugasValidasi() {
            let self = this;
            self.$router.push({ name: "add-surat_tugas_validasi" });
        }
    },
};
</script>
<style>
.col-md-custom {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
}

.list-inline {
    margin-top: 10px;
}

.hori-timeline .events {
    border-top: 3px solid #e9ecef;
}

.hori-timeline .events .event-list {
    display: block;
    position: relative;
    text-align: center;
    padding-top: 70px;
    margin-right: 0;
}

.hori-timeline .events .event-list:before {
    content: "";
    position: absolute;
    height: 36px;
    border-right: 2px dashed #dee2e6;
    top: 0;
}

.hori-timeline .events .event-list .event-date {
    position: absolute;
    top: 38px;
    left: 0;
    right: 0;
    width: 100px;
    margin: 0 auto;
    border-radius: 4px;
    padding: 2px 4px;
}

@media (min-width: 1140px) {
    .hori-timeline .events .event-list {
        display: inline-block;
        width: 24%;
        padding-top: 45px;
    }

    .hori-timeline .events .event-list .event-date {
        top: -12px;
    }
}

.bg-soft-primary {
    background-color: rgba(64, 144, 203, .3) !important;
}

.bg-soft-success {
    background-color: rgba(71, 189, 154, .3) !important;
}

.bg-soft-danger {
    background-color: rgba(231, 76, 94, .3) !important;
}

.bg-soft-warning {
    background-color: rgba(249, 213, 112, .3) !important;
}

.card {
    border: none;
    margin-bottom: 24px;
    -webkit-box-shadow: 0 0 13px 0 rgba(236, 236, 241, .44);
    box-shadow: 0 0 13px 0 rgba(236, 236, 241, .44);
}

.scroll-timeline {
    overflow: auto;
    white-space: nowrap;
}
</style>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <b-tabs content-class="p-3 text-muted">
                            <b-tab active class="border-0">
                                <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="fas fa-home"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">Detail Surat Tugas</span>
                                </template>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group col-md-12">
                                                    <label for="tipe_menu">Tanggal Surat Tugas</label>
                                                    <input type="date" name="" id="" class="form-control"
                                                        value="2023-07-21">
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label for="tipe_menu">Nomor Surat Tugas</label>
                                                    <input type="text" name="" id="" class="form-control"
                                                        value="0058/LSP/MUK/II/2023">
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label for="tipe_menu">Perihal</label>
                                                    <input type="text" name="" id="" class="form-control"
                                                        value="Surat Tugas Pra Asesmen">
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label for="tipe_menu">Nomor Jadwal Sertifikasi</label>
                                                    <input type="text" name="" id="" class="form-control"
                                                        value="0058/LSP/MUK/II/2023">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group col-md-12 mt-3">
                                                    <label for="">Surat Tugas Pra Asesmen</label><br>
                                                    <button v-on:click="modalSuratTugasPenyusunan"
                                                        class="btn btn-warning btn-sm"><i class="fas fa-eye"></i> Lihat
                                                        Surat
                                                        Tugas</button>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label for="tipe_menu">Keterangan</label>
                                                    <textarea name="" id="" cols="30" rows="10"
                                                        class="form-control"></textarea>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label for="tipe_menu">Asesi</label>
                                                    <select name="" id="" class="form-control">
                                                        <option value="">Pilih Asesi</option>
                                                        <option value="">Asesi 1</option>
                                                        <option value="" selected>Joko Kurniawan</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12">
                                            <!-- <label for="tipe_menu">Dokumen MUK</label> -->
                                            <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                                <thead class="bg-dark text-center text-white">
                                                    <tr>
                                                        <th>Dokumen</th>
                                                        <th>Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="meta_dokumen.length == 0">
                                                        <td colspan="3">TIdak Ada Data</td>
                                                    </tr>
                                                    <tr v-for="(item, index) in meta_dokumen" :key="index">
                                                        <td>
                                                            <input type="text" class="form-control"
                                                                v-model="item.nama_dokumen">
                                                        </td>
                                                        <td>
                                                            <a v-if="item.link" :href="link_backend + item.link"
                                                                target="_blank" class="btn btn-primary btn-sm"
                                                                style="margin-left: 3px;"><i class="fas fa-eye"></i> Lihat
                                                                Detail</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Metode Sertifikasi</label>
                                                    <select name="" id="" class="form-control">
                                                        <option value="">Pilih Metode Sertifikasi</option>
                                                        <option value="">Observasi</option>
                                                        <option value="">Portofolio</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 col-md-6">
                                                <div class="text-end">
                                                    <b-button type="button" class="m-1 btn-sm" variant="primary"><i
                                                            class="fas fa-save"></i>
                                                        Simpan</b-button>
                                                    <router-link :to="{ name: 'detail-pra_asesmen', params: { id: 1 } }"
                                                        class="btn btn-sm btn-danger bg-danger m-1"><i class="fa fa-times"></i>
                                                        Tolak</router-link>
                                                    <b-button type="button"
                                                        variant="success" class="m-1 btn-sm"><i class="fa fa-check"></i>
                                                        Terima</b-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <!-- <h4 class="card-title mb-5">Timeline</h4> -->
                            <div class="hori-timeline" dir="ltr">
                                <div class="scroll-timeline">
                                    <ul class="list-inline events">
                                        <li class="list-inline-item event-list">
                                            <div class="px-4">
                                                <div class="event-date bg-soft-primary text-primary">30 Juli 2023</div>
                                                <h6 class="font-size-14">Buat Surat Tugas</h6>
                                                <p class="text-muted">Admin HC</p>
                                                <div></div>
                                            </div>
                                        </li>
                                        <li class="list-inline-item event-list">
                                            <div class="px-4">
                                                <div class="event-date bg-soft-primary text-primary">30 Juli 2023</div>
                                                <h6 class="font-size-14">Proses Pra Asesmen</h6>
                                                <p class="text-muted">Admin LSP</p>
                                                <div></div>
                                            </div>
                                        </li>
                                        <li class="list-inline-item event-list">
                                            <div class="px-4">
                                                <div class="event-date bg-soft-success text-success">30 Juli 2023</div>
                                                <h6 class="font-size-14">Selesai</h6>
                                                <p class="text-muted">Admin LSP</p>
                                                <div></div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end card -->
                </div>
            </div>
        </div>
    </Layout>
</template>
